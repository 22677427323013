import React, { useEffect, useState } from "react";

import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { FaCoins } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { GiTakeMyMoney, GiGrowth, GiLevelThreeAdvanced } from "react-icons/gi";
import {
  MdAttachMoney,

  MdOutlineDirectionsWalk,
} from "react-icons/md";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { copy_refral, copy_wallet } from "../../../helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import moment from "moment/moment";
import RangeSlider from "react-bootstrap-range-slider";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { activateDscToken, activateUsdtToken, activatestDSCToken, getBalanceUser, getTokenPrice, getSploshPrice } from "../../../helpers/getWeb3";
import { DSC_Token_Address, USDT_Token_ADDRESS, stDSC_Token_Address } from "../../../helpers/config";
import { getDashBoardDetails, getUserDetailsByWallet, claimRewardAmt, checkFirstStakeAmount, checkreward } from "../../../helpers/api";
import { Loader } from "./Loader";
import Swal from "sweetalert2";
const Home = () => {

  const [loader, setLoader] = useState(true)
  const [userData, setUserData] = useState()
  const [userDashData, setUserDashData] = useState("")
  const [amount, setAmount] = useState(0)

  const [refresh, setRefresh] = useState(false)
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { walletAddress } = useSelector((action) => {
    return action.auth;
  });
  const [currentPrice, setCurrentPrice] = useState(0)
  const [tokenBalance, setTokenBalnce] = useState(0);
  const [sploshrate, setsplosh] = useState(0);
  const [receive, setreceive] = useState(0);
  const [basicModal, setBasicModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(USDT_Token_ADDRESS);
  const [selectedTokenName, setSelectedTokenName] = useState("USDT");
  const [type, setType] = useState("");
  const [tokenAmount, setTokenAmount] = useState("")
  const [packageStaking, setPackageStaking] = useState(10000);
  const [value, setValue] = useState(50);
  const [packageBtwenAmt, setPackagebtwAmt] = useState(50);
  const [percentage, setPercentage] = useState(0);
  const [totInc, settotinc] = useState(0);
  const [rank, setrank] = useState(0);
  const [rettturn, setrettturn] = useState(0);
  const [capping, setcapping] = useState(0);
  const [rewardbonus, setreward] = useState(0);
  const alltoken = [
    { address: USDT_Token_ADDRESS, symbol: "USDT" },
    // { address: stDSC_Token_Address, symbol: "st-DSC" },
    // { address: DSC_Token_Address, symbol: "DSC" }

  ]

  const planType = [
    { type: 1, symbol: "Flexi" },
    { type: 2, symbol: "Fix" },


  ]

  const handleTokenChange = (event) => {
    const { value, selectedOptions } = event.target;
    setSelectedTokenName({ value: value, name: selectedOptions[0].text });
    setSelectedToken(value);

  };

  const handleInputChange = (e) => {
    const inputValue = Number(e.target.value);
    //if (inputValue >= packageBtwenAmt && inputValue <= packageStaking) {
      setValue(inputValue);
    //}
  };

  const handleType = (event) => {
    const { value, selectedOptions } = event.target;
    //setType({ value: value, name: selectedOptions[0].text });
    setType(value);

  };

  const handleAmountChange = (e) => {
    const amount = parseFloat(e.target.value) || 0;
    setAmount(amount);

    if (amount > 0) {
        const receiveAmount = amount / sploshrate;
        setreceive(receiveAmount.toFixed(2));
    } else {
      setreceive(0);
    }
};

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  
  useEffect(() => {
    if (!selectedToken) return;

    getBalanceUser(walletAddress, selectedToken).then((res) => {
      const balance = Number(res?.value / 1e6) || 0;
      setTokenBalnce(balance);
    });

    getSploshPrice().then((res) => {
      const sploshpric = Number(res) || 0;
      console.log("sploshpric ",sploshpric)
      setsplosh(sploshpric);
    });

    if (selectedTokenName?.name === 'DSC') {
      getTokenPrice().then(setCurrentPrice);
    } else if (selectedTokenName?.name === 'USDT') {
      setCurrentPrice(1);
    } else {
      setCurrentPrice(89.28);
    }
  }, [selectedToken]);
  function Buy() {
    if (walletAddress) {
      
      if (selectedToken == USDT_Token_ADDRESS) {
       // if (type) {
          getUserDetailsByWallet(walletAddress).then((res) => {
            console.log(res, "call:::")
           // if (res?.isallow) {
              if (selectedTokenName?.name === 'DSC') {

                activateDscToken(
                  walletAddress,
                  value,
                  selectedToken,
                  type,
                  tokenAmount,
                  setLoading,
                  setRefresh
                );

              } else if (selectedTokenName === 'USDT') {
                console.log("value ", value);
                if(value > 100){
                  alert("Maximum Investment is $100")
                  return
                }
                console.log("tokenAmount ", tokenAmount);
      
                // Make the API call before proceeding
                checkFirstStakeAmount(walletAddress).then((apiRes) => {
                  // Assuming apiRes.data contains the response value
                  if (value >= apiRes.data) {
                    activateUsdtToken(
                      walletAddress,
                      res.userDetails.referrer,
                      value,
                      selectedToken,
                      type,
                      value,
                      setLoading,
                      setRefresh
                    );
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Not Allowed',
                      text: 'Stake greater than first stake amount. Please try again with an amount greater than ' + apiRes.data
                    });
                  }
                }).catch((err) => {
                  console.error("Error during API call: ", err);
                  Swal.fire({
                    icon: 'error',
                    title: 'API Error',
                    text: 'Failed to check first stake amount.'
                  });
                });
      
              } else {

                activatestDSCToken(
                  walletAddress,
                  value,
                  selectedToken,
                  type,
                  tokenAmount,
                  setLoading,
                  setRefresh
                );
              }

            // } else {

            //   toast.error('Activate limit Exceed !')
            // }
          });
        // } else {
        //   toast.error('Select type !')
        // }
      } else {
        toast.error('Select Token !')
      }
    }

  }
  useEffect(() => {
    // Fetch dashboard details
    getDashBoardDetails(walletAddress).then((res) => {
      setUserDashData(res);
    });
  
    // Fetch user details and calculate income, return, and percentage
    getUserDetailsByWallet(walletAddress).then((res) => {
      setUserData(res);
      checkreward(walletAddress);
  
      // Format totalIncome and returnAmount to two decimal places
      const totalIncome = parseFloat(res?.userDetails?.totalIncome || 0).toFixed(2);
      const rank = res?.userDetails?.rank;
      const rewardbnus = res?.userDetails?.rankbonus;
      const returnAmount = parseFloat(res?.userDetails?.return || 0).toFixed(2);
      const cap = res?.userDetails?.capping > 0 ? res?.userDetails?.capping : 2;
  
     
      
  
      // Set state for total income and return amount with two decimal places
      settotinc(totalIncome);
      setrank(rank);
      setrettturn(returnAmount);
      setreward(rewardbnus);
      setcapping(cap);
  
      // Calculate percentage, only if returnAmount is greater than 0
      let calculatedPercentage = 0;
      if (parseFloat(returnAmount) > 0 && parseFloat(totalIncome) > 0) {
        console.log("returnAmount ", returnAmount);
        console.log("totalIncome ", totalIncome);
        calculatedPercentage = (totalIncome / returnAmount) * 100;
      }
  
      console.log("calculatedPercentage ", calculatedPercentage.toFixed(2));
  
      // Set percentage and loader state
      setPercentage(calculatedPercentage.toFixed(2)); // Setting percentage to 2 decimal places
      setLoader(false);
    });
  }, [walletAddress]); // Dependency array ensures the effect runs on walletAddress change
  
  

  useEffect(() => {
    if (currentPrice > 0) {
      setTokenAmount(Number(currentPrice * value))
    }
  }, [value, currentPrice])

  return (
    <>
      <div className="row mt-4">
        {loader ? (
          <Loader />
        ) : (
          <>
           <div className="col-xl-12 mb-4">
  <div className="widget-stat card newcard1 bg-info">
    <div className="card-body p-4" style={{ background: "#0d0a38", borderRadius: "17px" }}>
      <div className="media-body text-white">
        <p className="mb-1">Capping {capping}x</p>
        <h4 className="text-white">{Math.round(percentage)}%</h4>
        <div className="d-flex align-items-center justify-content-between">
          {/* Total Income on the left */}
          <span className="text-white">${totInc}</span>
          
          {/* Range Slider */}
          <div style={{ flexGrow: 1, margin: "0 20px" }}>
            <RangeSlider
              value={percentage}
              min={0}
              max={100}
              tooltip="on"
              disabled
            />
          </div>

          {/* Total Return on the right */}
          <span className="text-white">${rettturn}</span>
        </div>
      </div>
    </div>
  </div>
</div>

            <div className="col-xl-8">
              <div className="row">

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card newcard1 bg-success">
                    <div className="card-body  p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="flaticon-381-calendar-1 "></i>
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1"> User ID</p>
                          {isVisible ? (
                            <h4 className="text-white">
                              {userData?.userDetails?.userId}
                            </h4>
                          ) : "*********"}

                          <div onClick={toggleVisibility} className=" mt-2 " style={{ cursor: 'pointer' }}>
                            {isVisible ? <MdVisibilityOff size={20} />
                              : <MdVisibility size={20} />

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card newcard1 bg-success">
                    <div className="card-body  p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="flaticon-381-calendar-1 "></i>
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1"> Sponsor Id</p>
                          
                              {userData?.userDetails?.referrerId === 'SPLH12345' ? (
                                <h4 className="text-white"></h4> 
                              ) : (
                                <h4 className="text-white">
                                  {userData?.userDetails?.referrerId}
                                </h4>
                              )}
                           
                          
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <BsFillCalendarDateFill />
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Joining Date & Time</p>
                          <h4 className="text-white">
                            {moment(userData?.userDetails?.createdAt).format(
                              "DD-MM-YYYY HH:mm:ss"
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {rank !== null && (
                    <div className="col-xl-6 col-lg-6 col-sm-6">
                      <div className="widget-stat card newcard1 bg-success">
                        <div className="card-body p-4">
                          <div className="media">
                            <span className="me-3">
                              <i className="flaticon-381-calendar-1"></i>
                            </span>
                            <div className="media-body text-white">
                              <p className="mb-1">Rank</p>
                              <h4 className="text-white">
                                {rank}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <AiOutlineTeam />
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Total Team</p>
                          <h4 className="text-white">{userDashData?.myTotalTeam ? userDashData?.myTotalTeam : 0}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <GiTakeMyMoney />
                        </span>
                        <div className="media-body text-white ">
                          <p className="mb-1">My Total Team Business (USDT)</p>
                          <h4 className="text-white">
                            {userDashData?.myTotalTeamBusiness ? userDashData?.myTotalTeamBusiness : 0}
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <MdOutlineDirectionsWalk />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">My Directs</p>
                          <h4 className="text-white">{userDashData?.myDirects ? userDashData?.myDirects : 0}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <i className="la la-dollar"></i>
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">Directs Business (USDT)</p>
                          <h4 className="text-white">
                            {userDashData?.myDirectsBusiness ? userDashData?.myDirectsBusiness : 0}
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div className="widget-stat card bg-success">
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3">
                          <FaCoins />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1">My Staking (USDT)</p>
                          <h4 className="text-white">
                            {userDashData?.myStaking ? userDashData?.myStaking : 0}

                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  >
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-success text-success">
                          <GiLevelThreeAdvanced />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1 ">
                            My Referral Bonus (USDT)
                          </p>
                          <h4 className="text-white">
                            {userDashData?.sponsorIncome ? userDashData?.sponsorIncome?.toFixed(4) : 0}
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  >
                    <div className="card-body p-4">
                      <div className="media ai-icon">
                        <span className="me-3 bgl-success text-success">
                          <GiLevelThreeAdvanced />
                        </span>
                        <div className="media-body text-white">
                          <p className="mb-1 ">
                            My Level Bonus (USDT)
                          </p>
                          <h4 className="text-white">
                            {userDashData?.myLevelBonus ? userDashData?.myLevelBonus?.toFixed(4) : 0}
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#9341c1" }}
                  >
                    {/* {console.log(userIncome,"income::")} */}
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3  bgl-danger text-ifo">
                          <MdAttachMoney />
                        </span>
                        <div className="media-body text-white">
                          <p className="">My Staking Bonus</p>
                          <h4 className="text-white">
                            {userDashData?.roiBonus ? userDashData?.roiBonus?.toFixed(4) : 0} {" "}

                            USDT
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <p className="">My Reward Bonus </p>
                          <h4 className="text-white">
                          {rewardbonus}
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#9341c1" }}
                  >
                    {/* {console.log(userIncome,"income::")} */}
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3  bgl-danger text-ifo">
                          <MdAttachMoney />
                        </span>
                        <div className="media-body text-white">
                          <p className="">Withdrawable Income (Remaining)</p>
                          <h4 className="text-white">
                            {userDashData?.walletincome ? userDashData?.walletincome?.toFixed(4) : 0} {" "}


                            USDT
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <p className="">Withdraw Income (Paid)</p>
                          <h4 className="text-white">
                            {userDashData?.totalWithdraw ? userDashData?.totalWithdraw?.toFixed(4) : 0} {" "}
                            USDT
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6  col-lg-6 col-sm-6">
                  <div
                    className="widget-stat card bg-success"
                  //style={{ background: "#7445cc" }}
                  >
                    <div className="card-body p-4">
                      <div className="media">
                        <span className="me-3  bgl-dark  text-danger">
                          <GiGrowth />
                        </span>
                        <div className="media-body text-white">
                          <p className="">Total Income </p>
                          <h4 className="text-white">
                            {userDashData?.totalIncome ? userDashData?.totalIncome?.toFixed(4) : 0} {" "}
                            USDT
                          </h4>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="row">
                <div className="col-xl-12 col-sm-6">
                  <div className="card h-auto">
                    <div className="card-body px-0 pt-1">
                      <Tab.Container defaultActiveKey="Navbuy">
                        <div className="">
                          <div className="buy-sell">
                            <Nav
                              className="nav nav-tabs"
                              eventKey="nav-tab2"
                              role="tablist"
                            >
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navbuy"
                                type="button"
                              >
                                Activate
                              </Nav.Link>
                              <Nav.Link
                                as="button"
                                className="nav-link"
                                eventKey="Navsell"
                                type="button"
                              >
                                Claim
                              </Nav.Link>
                            </Nav>
                          </div>
                          <Tab.Content>
                            <Tab.Pane eventKey="Navbuy">
                              <Tab.Container defaultActiveKey="Navbuymarket">
                                <Tab.Content id="nav-tabContent1">
                                  <Tab.Pane eventKey="Navbuymarket"></Tab.Pane>
                                  <Tab.Pane eventKey="Navbuylimit"></Tab.Pane>
                                </Tab.Content>
                                <div className="sell-element">
                                  {/* <div className="text-center">

                                    <span className="text-warning">Current Price :</span> {"  "}
                                    1 USDT ~ {currentPrice?.toFixed(4)} {"  "}{"  "}
                                    {selectedTokenName ? selectedTokenName?.name
                                      : null}


                                  </div> */}
                                  <div className="row">
                                    <div className=" text-end pt-2 text-success mb-2">
                                      {tokenBalance}{" "}
                                      {selectedTokenName ? selectedTokenName?.name
                                        : null}
                                      <span className="text-primary">
                                        {" "}
                                        Token Balnce{" "}
                                      </span>{" "}
                                    </div>

                                    <div className="col-xl-6 col-lg-6">
                                      <div className="d-flex justify-content-space-between">
                                        <div className="card-title pt-3 text-white">Token</div>
                                      </div>
                                    </div>
                                    <div className="ms-1 me-1">
                                      <select
                                        class="form-select form-select-sm"
                                        aria-label="Small select example"
                                        onChange={handleTokenChange}
                                        value={selectedToken}

                                      >
                                        <option value="" disabled>--Select Token --</option>

                                        {alltoken?.map((tkn, i) => {
                                          return (
                                            <option value={tkn.address} selected={tkn.symbol === "USDT"}>
                                              {tkn?.symbol}
                                            </option>
                                          );
                                        })}


                                      </select>
                                    </div>



                                    <div className="justify-content-center btn-register">
                                      <h5 className="card-title pt-1 text-white">
                                        Select Your Package
                                      </h5>

                                      {/* <RangeSlider
                                        value={value}
                                        min={packageBtwenAmt}
                                        max={packageStaking}
                                        onChange={(changeEvent) =>
                                          setValue(
                                            changeEvent.target.value < 50
                                              ? 50
                                              : changeEvent.target.value
                                          )
                                        }
                                        variant="success"
                                      /> */}
                                      <div className="row justify-content-center btn-register">
                                        <div className="col-4  mb-3 ">
                                          <button
                                            type="button"
                                            className="btn btn-primary ps-1 pe-1"
                                            onClick={() => {
                                              setPackageStaking(100);
                                              setPackagebtwAmt(50);
                                              setValue(50);
                                            }}
                                          >
                                            $50-$100 <br />
                                            {/* <span className="text-warning">  (Beginner)</span> */}
                                          </button>
                                        </div>
                                        <div className="col-4  mb-3 d-none">
                                          <button
                                            type="button"
                                            className="btn btn-primary ps-1 pe-1"
                                            onClick={() => {
                                              setPackageStaking(5000);
                                              setPackagebtwAmt(501);
                                              setValue(501);
                                            }}
                                          >
                                            $501-$5000 <br />
                                            {/* <span className="text-info">  (Learner)</span> */}
                                          </button>
                                        </div>
                                        <div className="col-4  mb-3 d-none">
                                          <button
                                            type="button"
                                            className="btn btn-primary ps-1 pe-1"
                                            onClick={() => {
                                              setPackageStaking(10000);
                                              setPackagebtwAmt(5001);
                                              setValue(5001);
                                            }}
                                          >
                                            $5001-$10000 <br />
                                            {/* <span className="text-danger">(Expert)</span> */}
                                          </button>
                                        </div>

                                      </div>

                                    </div>

                                    <div className="input-group my-3">
                                    <input
                                      type="number"
                                      value={value}
                                      min={packageBtwenAmt}
                                      max={packageStaking}
                                      onChange={handleInputChange}
                                      placeholder="Enter amount"
                                      style={{
                                        width: '100%',
                                        padding: '8px',
                                        fontSize: '16px',
                                        textAlign: 'center',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        background:'transparent',
                                        color:'white'
                                      }}
                                    />
                                    </div>

                                    <div className="ms-1 me-1 d-none">
                                      <h5 className="card-title pt-1 text-white">
                                        Select Type
                                      </h5>
                                      <select
                                        class="form-select form-select-sm"
                                        aria-label="Small select example"
                                        onChange={handleType}
                                        value={type}

                                      >
                                        <option value="" disabled>--Select Type --</option>

                                        {planType?.map((tkn, i) => {
                                          return (
                                            <option value={tkn?.type}>
                                              {tkn?.symbol}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div> 
                                    <div className="">

                                      <hr />
                                      {/* <div className="text-center">

                                        <span className="text-warning">Calculate :</span> {"  "}
                                        {value}  USDT ~ {((currentPrice) * value)?.toFixed(2)} {"  "}{"  "}
                                        {selectedTokenName ? selectedTokenName?.name
                                          : null}


                                      </div> */}
                                      {/* <div className="pt-1  mb-1">
                                        {" "}
                                        Note :
                                        <span className=" ps-1 text-success">

                                          {type == 1 ? 'Flexi: Can Withdraw Anytime Minimum $10' : type == 2 ? 'Fix:Can Withdraw Only When Package Becomes 200%' : null}


                                        </span>
                                      </div> */}



                                    </div>
                                  </div>

                                  <div className="text-center mt-3">
                                    {loading ? (
                                      <button class="btn btn-primary button-md btn-block mt-2 disabled">
                                        <span
                                          class="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        Loading...
                                      </button>
                                    ) : (
                                      <div
                                        onClick={() => Buy()}
                                        className="btn btn-primary w-75"
                                      >
                                        BUY
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Navsell">
                              <Tab.Container defaultActiveKey="Navsellmarket">
                              <div className="sell-element">
                                  <div className="sell-blance">
                                    <label className="form-label text-primary">
                                      Amount (USDT)
                                    </label>

                                    <div className="input-group mt-2 mb-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="10.00"
                                        value={amount}
                                        onChange={handleAmountChange} 
                                      />
                                    </div>
                                    <p>Balance : $ {userDashData?.walletincome?.toFixed(4)}</p>
                                  </div>
                                  <div className="sell-blance">
                                    <label className="form-label text-primary">
                                      SPLOSH
                                    </label>

                                    <div className="input-group mt-2 mb-2">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0"
                                        value={receive}
                                      />
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    <p>Splosh : $ {sploshrate}</p>
                                    <div
                                      className="btn btn-primary w-75"
                                      onClick={() => {
                                        if (amount >= 10) {
                                          claimRewardAmt(walletAddress, amount);
                                        } else {
                                          Swal.fire({
                                            icon: 'error',
                                            title: 'Info',
                                            text: 'Amount must be at least $10.'
                                          });
                                          //alert("Amount must be at least $10.");
                                        }
                                      }}
                                    >
                                      Claim Reward
                                    </div>
                                  </div>
                                </div>
                              </Tab.Container>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 col-sm-6">
                  <div className="card">
                    <div className="card-header py-2">
                      <h2 className="heading">Referral Id </h2>
                    </div>
                    <div className="input-group mb-3 p-2 mt-2">
                      <input
                        id="copy"
                        type="text"
                        className="form-control"
                        value={`https://splosh.app/register?user_id=${userData?.userDetails?.userId}`}
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => copy_refral()}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-xl-12 col-sm-6">
                  <div className="card">
                    <div className="card-header py-2">
                      <h2 className="heading">Wallet Address </h2>
                    </div>
                    <div className="input-group mb-3 p-2 mt-2">
                      <input
                        id="copywallet"
                        type="text"
                        className="form-control"
                        value={walletAddress}
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => copy_wallet()}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <Modal className="fade" show={basicModal}>
              <Modal.Header>
                <Modal.Title>Claim Income</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setBasicModal(false)}
                ></Button>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  <div className="fs-6 fw-bold">
                    <span className="text-warning">
                      <span className="text-dark ">Total :</span>{" "}
                      0 Usdt
                    </span>
                  </div>
                  <div className="fs-6 fw-bold">
                    <span className="text-info">
                      <span className="text-dark ">Total Token Received :</span>{" "}
                      0
                      Token
                    </span>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setBasicModal(false)}
                  variant="danger light"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {

                    setBasicModal(false);
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};
export default Home;
